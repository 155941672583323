@font-face {
  font-family: "Open Sans";
  font-display: swap;
  src: url("./fonts/Open_Sans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  src: url("./fonts/Gilroy/gilroy-regular.ttf");
}
@font-face {
  font-family: "Monserrat";
  font-display: swap;
  src: url("./fonts/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Monserrat Med";
  font-display: swap;
  src: url("./fonts/Montserrat/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Monserrat Bold";
  font-display: swap;
  src: url("./fonts/Montserrat/Montserrat-Bold.ttf");
}
html {
  box-sizing: border-box;
  background-color: #FCFBF8;
}
body {
  margin: 0;
  height: 100vh;
  font-family: Open Sans, Arial, sans-serif;
  background-color: #F6F3EB;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}
.react-datepicker-popper {
  z-index: 2!important;
}
input:disabled { background-color: transparent!important; }
/* Fondy */
#checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
}
#f {
  margin-top: 2rem!important;
  border-radius: 40px!important;
  max-width: 610px;
  display: flex;
  border: none!important;
  box-shadow: none!important;
}
#f .f-min .f-center,
#f .f-min .f-info 
{
  width: fit-content!important;
  left: unset!important;
  right: unset!important;
}
.f-center, .f-wrapper {
  height: auto!important;
}
#f .f-wrapper {
  display: flex!important;
  display: -webkit-flex!important;
  justify-content: space-between!important;
  -webkit-justify-content: space-between!important;
  -moz-justify-content: space-between!important;
  flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  border: none!important;
  box-shadow: none!important;
  border-radius: 40px!important;
  max-width: 100%!important;
}
#f .f-center {
  height: auto!important;
  min-width: 320px;
  padding: 0!important;
  border: none!important;
  box-shadow: none!important;
  border-radius: 40px!important;
}
#f .f-container {
  padding: 1.5rem 0 0.5rem!important;
  height: unset!important;
  margin: 0 auto!important;
}
.f-header {
  padding: 0 1.5rem 1rem 2rem!important;
}
.f-block-title {
  font-family: "Montserrat Med", Arial, sans-serif;
}
.f-wallet-pay-button, .f-icons {
  display: none!important;
}
#f .f-btn-block {
  position:absolute;
  bottom: -4.5rem;
  left: -1rem;
  background-color: #000000;
  border: solid thin #000000;
  width: 610px!important; 
  font-size: 1rem;
}
#f .f-block, #f .f-block div {
  padding: 0.25rem!important;
  word-wrap: break-word;
}
#f .f-block div {
  /* max-width: 50%!important; */
  white-space: pre;
}
#f .f-info, #f .f-icons {
  border: none!important;
}
#f .f-icons {
  margin: 0.5rem 0!important;
}
/* .f-block-sm { */
  /* display: flex!important;
  display: -webkit-flex!important;
  flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: flex-start!important;
  -webkit-justify-content: flex-start!important;
  -moz-justify-content: flex-start!important;
} */
.f-row::before, .f-row::after {
  content: ''!important;
}
.f-form-group {
  margin-bottom: 0!important;
}
.f-block-title ~ div {
  display: none;
}
.f-block-title {
 color: rgb(173, 173, 173)!important;
 font-weight: normal!important;
}
.btn:focus, input:focus, .form-control:focus {
  outline: none!important;
  outline-offset:0!important;
  outline-style: none!important;
  outline-width: 0px !important;
  outline-color: none !important;
  box-shadow: 0 0 0 0;
}
a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus, 
input[type="checkbox"]:active.focus,
input[type="checkbox"]:active:focus,
input[type="checkbox"].active:focus,
input[type="checkbox"].active.focus   {
outline: 0!important;
outline-color: transparent!important;
outline-width: 0!important;
outline-style: none!important;
box-shadow: 0 0 0 0!important;
}
.error {
  border-color: #E29090!important;
}
.custom-control-input ~ .custom-control-label::before, .custom-control-input:focus ~ .custom-control-label::before, .custom-control-input:active ~ .custom-control-label::before, .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #36454F!important;
  border: solid 2px #36454F!important;;
  box-shadow: none!important;
}
.custom-control-input ~ .custom-control-label::before svg {
  fill: none;
  stroke: black;
  stroke-width: 2px;
}
.custom-checkbox {
  margin: 0 1rem 0 0.25rem;
}
.form_inline_block, .form_inline_block_inner {
  display: flex;
  display: -webkit-flex;
}
.form_inline_block > * {
  width: 48%;
}
.form_inline_block_inner {
  justify-content: space-evenly!important;
}
.form_inline_block_inner .form-label {
   width: 80%!important;
}
.form_flex_basis {
  width: 50%;
}
.bl_bottom_info {
  position: relative;
  padding: 1.25rem 2rem 1.25rem 4rem;
  font-size: 0.875rem;
  background-color: #F6F3EB;
  border-radius: 12px;
  /* --smooth-corners: 30;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.bl_bottom_info::before {
  content: url('./fonts/takeoff.svg');
  position: absolute;
  left: 2rem;
}
.lg_screen {
  display: inline;
}
.sm_screen, .sdf_link_sm_screen, .cart_btns-content {
  display: none;
}

.sdf_link_lg_screen {
  display: block;
}

/* Layout */
header {
  color: #1D1F1C;
  background-color: #F6F3EB;
  border: solid 1px #D9D9D9;
  width: 100%;
}
header a {
  padding: 0.25rem 1.25rem;
  color: #1D1F1C!important;
}
header a:hover {
  opacity: 0.7;
}
.cart {
  /* min-width: 21%!important; */
  padding-top: 3.75rem!important;
  padding-bottom: 2rem!important;
}
.cart Button {
  min-width: 10.5rem;
  background-color: #000000!important;
  border: none;
  font-size: 0.875rem!important;
  font-family: Open Sans, Arial, sans-serif!important;
  /* --smooth-corners: 10;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.navbar {
  padding-left: 0!important;
  padding-right: 0!important;
}
.navbar-nav {
  flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  -moz-flex: 1;
  align-items: center!important;
  -webkit-align-items: center!important;
  justify-content: space-around!important;
  -webkit-justify-content: space-around!important;
  -moz-justify-content: space-around!important;
  margin-top: 0.375rem;
}
.navbar-nav > * {
  padding: 1.25rem 0.5rem 0;
}
.navbar-brand {
  margin-top: 0.375rem;
  padding-bottom: 0!important;
}
.navbar-collapse.show > .navbar-nav .nav-link, .navbar-collapse.collapsing > .navbar-nav .nav-link {
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: solid thin #D9D9D9;
}
.navbar-collapse.show > .navbar-nav .menu-selects,
.navbar-collapse.collapsing > .navbar-nav .menu-selects {
  width: 100%;
  justify-content: space-around;
}
.cart_icon {
  position: relative;
  padding: 0 0.4rem;
}
.booking-block_cart .cart_icon {
  padding: 0 0.4rem 0 0!important;
}
.booking-block_cart div:first-child {
  font-size: 14px!important;
}
.cart_icon-index {
  position: absolute;
  font-size: 0.6rem;
  right: 1.84rem;
  top: 0.6rem;
  z-index: 5;
}
.cart_icon_sm {
    width: 30px;
    height: 22px;
    padding: 0 0.4rem;
}
header form {
  display: flex;
}
header .form-group {
  margin: 0;
  position: relative;
}
header .form-control {
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  background-color: #ECE6D5;
  background-image: url("./fonts/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 0.375rem) center;
  border: solid thin #ECE6D5;
  border-radius: 8px;
  text-align: center;
  height: unset;
  /* --smooth-corners: 10;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
header .form-control:hover, header .form-control:focus, header .form-control:active {
  background-color: #ECE6D5;
  border: solid thin #ECE6D5;
  -webkit-appearance: none;
  text-decoration: none;
  outline: none!important;
  border: solid thin #ECE6D5;
  box-shadow: 0 0 15px #ECE6D5;
}
.quantity .form-control {
  height: calc(1.5em + 0.75rem + 2px);
}
.quantity input:disabled {
  color: #000000!important;
  opacity: 1!important;
}
.breadcrumb {
  background-color: transparent!important;
  margin-top: 1.5rem;
}
.breadcrumb-item span {
  cursor: pointer;
}
.breadcrumb-item.active {
  pointer-events: none!important;
}
.breadcrumbs_bg {
  background-color: #f5efe3;
}
main {
  padding-bottom: 6.25rem;
  background-color: #F6F3EB;
  min-height: 60vh;
}
.main-banner {
  display: flex;
  display: -webkit-flex;
  padding: 4rem 0;
  background-image: url("./fonts/airstairs-lg.webp");
  background-image: image-set(
    url("./fonts/airstairs-lg.webp") type("image/webp"),
    url("./fonts/airstairs-lg.jpg") type("image/jpeg"));
  background-image: -webkit-image-set(
    url("./fonts/airstairs-lg.webp") type("image/webp"),
    url("./fonts/airstairs-lg.jpg") type("image/jpeg"));
  background-repeat: no-repeat;
  background-position: 95% center;
}
.covid.main-banner {
  padding: 2.5rem 0!important;
}
.partners.main-banner {
  background-image: url("./fonts/partners_img.png");
}
.about.main-banner {
  background-image: url("./fonts/about-banner.png");
  background-position-y: 70px;
}
.fastline.main-banner {
  background-image: url("./fonts/fastline-main-banner.png");
  background-position-y: center;
}
.fastline.main-banner img {
  width: 80px;
  height: 80px;
}
.about.main-banner h1 {
  font-size: 40px;
}
.about.main-banner .stats-block-wrap {
  background-color: transparent;
  border: none;
}
.about.main-banner .stats-block {
  flex-direction: column;
  margin: 0!important;
  padding: 0!important;
}
.about.main-banner .stats-airports {
  align-self: flex-start;
}
.about.main-banner .aiports-imgs {
 display: block; 
 margin: 0.5rem 0 1.5rem;
}
.about-company {
  line-height: 28px;
  text-align: left;
}
.about-company_descr span {
  text-align: left;
}
.about-company img {
  max-width: 50%;
  height: auto!important;
  float: left;
  display: inline-block;
  margin: 0 3rem 1rem 0;
}
.about .stats-airports img {
  margin-left: 0;
  margin-right: 2rem;
}
.lounge-wrapper {
  background-image: url("./fonts/lounge-main-banner.webp");
  background-image: image-set(
    url("./fonts/lounge-main-banner.webp") type("image/webp"),
    url("./fonts/lounge-main-banner.jpg") type("image/jpeg"));
  background-image: -webkit-image-set(
    url("./fonts/lounge-main-banner.webp") type("image/webp"),
    url("./fonts/lounge-main-banner.jpg") type("image/jpeg"));
  background-repeat: no-repeat;
  background-size: 50vw 100%!important;
  background-position: 50vw top!important;
}
.covid-wrapper {
  background-image: url("./fonts/covid-main-banner.jpg")!important;
  background-repeat: no-repeat;
  background-size: cover!important;
  background-position: center bottom!important;
}
.advantages {
  background-image: url("./fonts/lounge-advantages.webp")!important;
  background-image: image-set(
    url("./fonts/lounge-advantages.webp") type("image/webp"),
    url("./fonts/lounge-advantages.jpg") type("image/jpeg"));
  background-image: -webkit-image-set(
    url("./fonts/lounge-advantages.webp") type("image/webp"),
    url("./fonts/lounge-advantages.jpg") type("image/jpeg"));
}
.lounge-is-wrapper {
  background-image: url("./fonts/lounge-is.webp");
  background-image: image-set(
    url("./fonts/lounge-is.webp") type("image/webp"),
    url("./fonts/lounge-is.jpg") type("image/jpeg"));
  background-image: -webkit-image-set(
    url("./fonts/lounge-is.webp") type("image/webp"),
    url("./fonts/lounge-is.jpg") type("image/jpeg"));
  background-position: right 50vw top;
  background-repeat: no-repeat;
  background-size: 50vw 100%;
}
.lounge.main-banner {
  background-image: none;
}
.partners.main-banner p {
  margin: 2.5rem 0;
}
.main-banner_left {
  width: calc(50% - 60px);
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
}
.main-banner_left h1 {
  padding: 0;
  font-size: 3rem;
  font-family: Monserrat Bold, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.4;
}
.lounge .main-banner_left h1:not(.covid.lounge .main-banner_left h1) {
  white-space: pre;
}
.main-banner_left-icons {
  display: flex;
  display: -webkit-flex;
  margin: 2.5rem 0 3rem;
}
.main-banner_left-icons a {
  margin-right: 50px;
}
.lounge .main-banner_left {
  width: 50%!important;
  padding-right: 60px;
  justify-content: center!important;
  -webkit-justify-content: center!important;
  -moz-justify-content: center!important;
  align-items: flex-start!important;
  -webkit-align-items: flex-start!important;
  -moz-align-items: flex-start!important;
}
.covid .main-banner_left, .about .main-banner_left {
  width: 40%!important;
}
figure {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  margin: 0!important;
}
figcaption {
  margin-top: 1rem;
  text-align: center!important;
  font-size: 1rem;
}
.main-banner_left-link {
  width: fit-content!important;
  font-size: 1.25rem;
  font-family: Monserrat, Arial, sans-serif;
  font-weight: 600;
  text-align: center;
}
.main-banner_left-link {
  padding: 1rem 2rem;
  border: 1px solid #090909;
  border-radius: 15px;
}
.stats-block-wrap, .booking-block-wrap {
  background-color: #FCFBF8;
  border: 1px solid #EFEADC;
  padding: 2rem 0;
}
.stats-block, .stats-numbers {
  display: flex!important;
  display: -webkit-flex!important;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
}
.stats-airports, .stats-numbers {
  flex-basis: 47.5%;
  -webkit-flex-basis: 47.5%;
  -moz-flex-basis: 47.5%;
}
.stats-airports {
  font-size: 1.125rem;
  font-weight: 500;
  font-family: Monserrat Med, Arial, sans-serif!important;
  color: #8C8C8C;
}
.aiports-imgs {
  display: inline-block;
}
.stats-airports img {
  margin-left: 0.7rem;
}
.stats-numbers {
  align-items: flex-start;
  -webkit-align-items: flex-start;
}
.stats-numbers div {
  flex-basis: 30%;
  -webkit-flex-basis: 30%;
  -moz-flex-basis: 30%;
}
.stats-numbers div:last-child {
  flex-basis: 35%;
  -webkit-flex-basis: 35%;
  -moz-flex-basis: 35%;
}
.stats-number {
  font-size: 1.25rem;
  font-weight: 700;
  font-family: Monserrat Bold, Arial, sans-serif!important;
  color: #1D1F1C;
}
.stats-descr {
  font-size: 0.875rem;
  color: #8C8C8C;
}
.services-block, .services-block_categories > *, .fl-services-block_categories > * {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  padding: 2rem 0;
  text-align: center;
}
.fl-services-block_categories {
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
}
.block_title {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  align-items: left;
  -webkit-align-items: left;
  -moz-align-items: left;
  padding: 2rem 0;
  text-align: left;
}
.about-services .block_title {
  margin-bottom: 2.5rem;
  padding: 2.5rem 10rem 1.5rem;
  background-color: #ffffff;
  border-radius: 50px;
  /* text-align: center; */
}
.about-services .block_title img {
  margin: 0 auto 1rem;
  width: 180px;
}
.fastline_bg {
  background-image: url('./fonts/fastline_bg.png');
  /* background-image: image-set(
    url('./fonts/fastline_bg.avif') type('image/avif'),
    url('./fonts/fastline_bg.png') type('image/png'));
  background-image: -webkit-image-set(
    url('./fonts/fastline_bg.avif') type('image/avif'),
    url('./fonts/fastline_bg.png') type('image/png')); */
}
.lounge_bg {
  background-image: url('./fonts/lounge_bg.png');
  /* background-image: image-set(
    url('./fonts/lounge_bg.avif') type('image/avif'),
    url('./fonts/lounge_bg.png') type('image/png'));
  background-image: -webkit-image-set(
    url('./fonts/lounge_bg.avif') type('image/avif'),
    url('./fonts/lounge_bg.png') type('image/png')); */
}
.covid_bg {
  background-image: url('./fonts/covid_bg.png');
  /* background-image: image-set(
    url('./fonts/covid_bg.avif') type('image/avif'),
    url('./fonts/covid_bg.png') type('image/png'));
  background-image: -webkit-image-set(
    url('./fonts/covid_bg.avif') type('image/avif'),
    url('./fonts/covid_bg.png') type('image/png')); */
}
.about-services .services-block_category {
  height: auto;
  width: 31%;
  background-color: #ffffff;
}
.fl-services-block_category {
  padding: 30px 30px 80px;
  margin: 20px 0 30px;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  background-color: #ffffff;
  border-radius: 40px;
  /* width: 23%; */
  width: 31%;
  position: relative;
}
/* .fl-services-block_category:nth-child(4), .fl-services-block_category:last-child {
  width: 48%;
} */
.fl-services-block_category:nth-child(4) {
  background-color: #000000;
  color: #ffffff;
}
.fl-services-block_category hr {
  color: lightgray;
  height: 1px;
  width: 100%;
}
.fl-services-block_category:nth-child(4) hr {
  color: white!important;
  opacity: 0.8;
  border-top: solid thin white;
}
.fl-services-block_category img {
  width: 35px;
  height: 35px;
}
.fl-services-block_category-name {
  margin: 15px 0 10px;
  font-weight: 600;
  font-size: 17px;
  color: #1D1F1C;
  font-family: Monserrat, Arial, sans-serif;
}
.fl-services-block_category-descr {
  font-size: 13px; 
  color: #060604;
  opacity: 0.6;
  font-family: Monserrat Med, Arial, sans-serif;
}
.fl-services-block_category-details {
  margin: 20px 0;
  font-size: 14px;
  color: #1D1F1C;
}
.fl-service-book {
  position: absolute;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #000000;
  color: #ffffff!important;
  font-size: 14px!important;
  padding: 7px 10px 10px!important;
  width: 70%!important;
}
.fl-services-block_category:nth-child(4) .fl-service-book {
  background-color: #ffffff;
  color: #000000!important;
}
.fl-services-block_category:nth-child(4) .fl-services-block_category-name,
.fl-services-block_category:nth-child(4) .fl-services-block_category-descr,
.fl-services-block_category:nth-child(4) .fl-services-block_category-details {
  color: #ffffff;
}
.fl-services-block_category:nth-child(4) .fl-services-block_category-descr {
  /* margin-bottom: 2rem;
  margin-top: 1rem; */
  white-space: pre-line;
}
.fl-services-block .inner-wr {
  min-height: 180px;
}
.about-services .services-block_category img {
  height: auto;
}
.about-services .services-block_category-name {
  height: auto;
  margin: 1rem 0;
  color: #000000;
}
.block_title-tag {
  color: #AFA897;
  font-size: 0.875rem;
}
.block_title-name {
  color: #1D1F1C;
  font-size: 2.25rem;
  font-family: Monserrat Bold, Arial, sans-serif;
}
.lounge-is-block .block_title-name {
  white-space: pre;
}
.fl-services-block .block_title-name {
  font-size: 1.7rem;
  text-align: center;
}
.block_title-line {
  margin: 1rem 0;
  background-color: #757575;
  height: 0.1875rem;
  width: 14rem;
  font-family: Monserrat Bold, Arial, sans-serif;
}
.services-block_categories, .contacts-block_main, .contacts-block_sm, .contacts-block_tel, .fl-services-block_categories {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  width: 100%;
}
.contacts-block_sm {
  justify-content: space-evenly!important;
  -webkit-justify-content: space-evenly!important;
  -moz-justify-content: space-evenly!important;
}
.services-block_category {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  padding: 2rem 1.25rem;
  width: 32%;
  height: 31rem;
  background-size: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 70px;
  /* --smooth-corners: 10;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.partners.services-block_category {
  height: auto!important;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  padding: 2.5rem 3.3rem;
  background-color: white;
  border-radius: 60px;
}
.partners.services-block_category form {
  width: 100%;
}
.partners.services-block_category.commission {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
  text-align: left;
  width: 47.5%;
}
.partners.services-block_category.commission.partners-category {
  padding-top: 6rem;
  width: 46%;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  text-align: center;
  position: relative;
}
.partners.services-block_category.commission Button {
  background-color: #000000;
}
.partners.services-block_category p {
  font-size: 1rem;
  font-weight: normal;
  color: #000000;
  font-family: Open Sans, Arial, sans-serif;
}
.partners.services-block_category img {
  margin-bottom: 2rem;
  max-width: calc(100% - 2rem);
}
.partners.services-block_category h6 {
  margin-bottom: 2rem;
  font-size: 1.5rem!important;
  font-weight: 600;
  font-family: "Monserrat Med", Arial, sans-serif;
}
.services-block_category-name {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  height: 35%;
  margin-top: 4.5rem;
  margin-bottom: 0;
  font-family: Monserrat, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: #ffffff;
}
.partners .services-block_category-name {
  height: auto!important;
  margin-top: initial!important;
}
.services-block_category-name:not(.partners .services-block_category-name) {
  white-space: pre;
}
.services-block_category-descr {
  width: 80%;
  font-size: 0.875rem;
  color: #665A55;
}
.services-block_category-book {
  padding: 0.625rem 1.25rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #000000;
  border: 1.5px solid #000000;
  border-radius: 8px;
}
.services-block_category-more {
  margin-top: 1.25rem;
  font-family: Monserrat Med, Arial, sans-serif;
  font-size: 0.8125rem;
  color: #1D1F1C;
}
.partners-instr-block {
  width: 100%;
  margin-bottom: 5rem;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
}
.partners-instr-block:last-child {
  margin-bottom: initial!important;
}
.partners-instr-block:nth-child(2) {
  flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
}
.partners-instr-block .services-block_category {
  width: 58%;
}
.partners-category-img {
  position: absolute;
  top: -4.5rem;
  margin-bottom: 0!important;
}
.partners-instr-block img {
  width: 38%;
}
.booking-block {
  background-color: #FCFBF8;
  padding: 0 0 2rem!important;
}
.booking-block_main, .fl_option_short, .fl_option_details, .fl_option_short-left {
  display: flex;
  display: -webkit-flex;
}
.booking-block_main h5, .booking-block_main h6 {
  font-family: Monserrat Bold, Arial, sans-serif;
}
.booking-block_main {
  padding-top: 2rem;
  border-top: solid thin #D9D9D9;
}
.booking-block_aside, .fl_option {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
}
.checkout_block_cart #accordion .fl_option {
  opacity: 1;
  transition: all 1s linear;
}
#termsAgreement ~ label {
  font-size: 14px;
}
#termsAgreement ~ label a {
  text-decoration: underline!important;
}
.hidden {
  height: 0!important;
  margin: 0!important;
  padding: 0!important;
}
.visuallyhidden {
  opacity: 0!important;
}
  
.sdf_link {
  max-width: 14rem;
  margin-top: 2rem;
  padding-bottom: 2rem;
  background: #B8D6D2;
  border-radius: 35px;
  /* --smooth-corners: 8;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.sdf_link h6, .sdf_link p, .sdf_link a:not(.sdf_link h6 a) {
  margin: 0.6rem 1.25rem;
}
.sdf_link h6 {
  margin-top: 1.2rem;
  text-align: left;
  font-family: Monserrat, Arial, sans-serif;
}
.sdf_url {
  color: #000000;
  font-size: 0.875rem;
  text-decoration: underline;
  background-color: unset;
}
.fl_option {
  position: relative;
  width: 100%;
  padding: 1.25rem 1.25rem 2rem;
  margin: 1rem 0;
  background-color: #F3F0E7;
  border-radius: 20px;
  /* --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.business-style {
  background-color: #000000;
}
.business-style > *, .business-style .fl_option_short p {
  color: #ffffff!important;
}
.business-style .fl_option_order-btn {
  background-color: #000000;
  border: solid thin #ffffff;
  color: #ffffff;
}
.business-style .fl_option_collapse-btn {
  background-color: #42413E;
}
.business-style .fl_option_details li {
  color: #ffffff;
}
.fl_option_details {
  border-top: solid thin #D9D9D9;
}
.fl_option_details-inner:not(.checkout_block_cart .fl_option_details-inner) {
  margin-left: 2rem;
}
.fl_option_details-inner ul {
  padding: 1.25rem 0 0 1.25rem;
  list-style-image: url("./fonts/Point_Icon.svg");
}
.business-style .fl_option_details-inner ul {
  list-style-image: url("./fonts/Point_Icon-white.svg");
}
.fl_option_details-inner li {
  margin: 0.5rem 0;
  font-size: 0.875rem;
  color: #1D1F1C;
}
.booking-block_menu, .booking-block_cart {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  max-width: 14rem;
  padding: 2rem 1.5rem;
  color: #FFFFFF;
  background-color: #000000;
  border-radius: 20px;
  /* --smooth-corners: 8;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.booking-block_cart.hidden {
    font-size: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    height: 0;
    transition: opacity .25s,
                font-size .5s .25s,
                margin .5s .25s,
                padding .5s .25s;
}
.booking-block_cart.hidden ul {
  border: none;
  margin: 0;
  padding: 0;
}
.booking-block_cart.hidden div {
  display: none;
}
.booking-block_cart.hidden .cart-checkout-link {
  display: none;
}
.booking-block_cart {
  max-width: 14rem;
  margin-top: 2rem;
  transition: font-size .25s,
                margin .25s,
                padding .25s,
                opacity .5s .25s;
}
.cart-checkout-link {
  font-size: 0.875rem;
}
.booking-block_menu, .booking-block_cart .title, .monserrat {
  font-family: Monserrat Med, Arial, sans-serif!important;
}
.booking-block_menu span {
  margin-bottom: 1rem;
  text-align: center;
}
.booking-block_menu li {
  position: relative;
  padding: 1.25rem 1rem 1.25rem 3rem;
  font-size: 0.8125rem;
  cursor: pointer;
}
.booking-block_menu li::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
}
.booking-block_menu li:first-child::before {
  content: url("./fonts/fastline_icon.svg");
}
.booking-block_menu li:nth-child(2)::before {
  content: url("./fonts/covid_icon.svg");
}
.booking-block_menu li:nth-child(3)::before {
  content: url("./fonts/lounge_icon.svg");
}
.booking-block_menu li:nth-child(4)::before {
  content: url("./fonts/so_icon.svg");
}
.booking-block_menu li.active {
  background-color: #5C5C5C;
  border-radius: 8px;
  /* --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.booking-block_cart ul {
  margin: 1.5rem 0!important;
  padding: 0.75rem 0!important;
  border-top: dashed thin #ffffff;
}
.booking-block_cart ul li {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  width: 109%;
  margin: 0.5rem 0;
  font-size: 0.8125rem;
  cursor: pointer;
}
.booking-block_cart ul li:hover .trash_icon {
  visibility: visible;
}
.booking-block_cart a {
  font-size: 0.875rem;
}
.booking-block_cart .not_in_cart_line {
  width: 100%;
}
.booking-block_cart .plus_icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.booking-block_cart.hidden .plus_icon {
  visibility: hidden;
}
.trash_icon {
  visibility: hidden;
  height: 0.7rem;
  width: 0.6rem;
  margin-left: 0.4rem;
  cursor: pointer;
}
.booking-block_content {
  flex: 1;
  -webkit-flex: 1;
  padding-left: 2rem;
}
.fl_option_short {
  min-height: 4.75rem;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.fl_option_short p, .sdf_link p {
  font-size: 0.8125rem;
  color: #060604;
}
.fl_option_short-left {
  padding-right: 1rem;
}
.fl_option_short-left img {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.8125rem;
}
.fl_option_short-right {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -webkit-align-items: center;
}
.fl_option_order-btn {
  width: 12rem;
  padding: 0.625rem 0.5rem;
  border: solid thin #000000;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  outline: none;
  /* --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.fl_option_collapse-btn {
  position: absolute;
  bottom: 0;
  right: 1.25rem;
  width: 12rem;
  height: 2rem;
  padding: 0 0.8rem 0 0;
  background-color: #E7E1D0;
  background-repeat: no-repeat;
  background-position: 92% center;
  border: none;
  border-radius: 0.5rem 0.5rem 0 0;
  color:#6F664D;
  font-size: 0.8125rem;
  /* --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.fl_option_collapse-btn.copy-btn {
  position: static!important;
  width: auto!important;
  height: auto!important;
  border-radius: 0.5rem!important;
  padding: 0.5rem 1rem!important;
  margin: 10px 10px 10px 0!important;
}
.fl_option_collapse-btn.copy-btn:hover {
  opacity: 0.8;
}
.checkout_block .fl_option_collapse-btn {
  width: 8.5rem;
}
.checkout_block_cart, .checkout_block_personal {
  width: 48.5%;
  padding: 1.3rem 1.5rem;
  border-radius: 40px;
  background-color: #ffffff;
}
#f .btn {
  font-family: Monserrat, Arial, sans-serif;
}
.checkout_block_personal .btn:not(.copy-btn) {
  background-color: #000000;
  padding: 1.25rem 0;
  margin: 10px 0 12px;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: bold;
  font-family: Monserrat, Arial, sans-serif;
}
input[id^="checkoutPhone"] {
  padding-left: 0.7rem!important;
}
.checkout_block_personal input,  .partners.commission input, .partners.commission select, .checkout_block_personal textarea, .checkout_block_personal select, .checkout_block_personal option,
.checkout_block_personal input:active, .checkout_block_personal textarea:active, .checkout_block_personal select:active, .checkout_block_personal option:active,
.checkout_block_personal input:focus, .checkout_block_personal textarea:focus, .checkout_block_personal select:focus, .checkout_block_personal option:focus, .partners.commission input:active, .partners.commission select:active,
.partners.commission input:focus, .partners.commission select:focus {
  border: solid thin #F7F4EE;
  background-color: #F7F4EE;
  border-radius: 10px;
  height: auto!important;
  margin: 10px 0!important;
  padding: 12px 0.7rem 12px 2.5rem!important;
  outline: none!important;
  text-shadow: 0 0 0 #000!important;
  font-size: 14px;
  color: #000000;
  background-repeat: no-repeat!important;
  background-position: 1rem center;
  /* appearance: none;
  -moz-appearance: none;
-webkit-appearance: none; */
}
.checkout_block_personal textarea,
  .checkout_block_personal textarea:active, .checkout_block_personal textarea:focus {
  padding-left: 1rem!important;
}
.checkout_block_personal input::placeholder, .checkout_block_personal textarea::placeholder, .checkout_block_personal select::placeholder {
  color: #AFAA9D;
  font-size: 14px;
}
.checkout_block_personal .row {
  margin-right: -0.5rem!important;
  margin-left: -0.5rem!important;
}
.checkout_block_personal .col, .checkout_block_personal .col-sm-4, .checkout_block_personal .col-sm-8, .checkout_block_personal .col-sm-6, .checkout_block_personal .col-5, .checkout_block_personal .col-7 {
  padding: 0 0.5rem!important;
}
.checkoutDate {
  background-image: url("./fonts/calendar.svg");
  width: 100%!important;
}
.react-confirm-alert, .react-confirm-alert-body {
  border-radius: 1rem;
}
.react-confirm-alert-body {
  display: flex!important;
  display: -webkit-flex!important;
  flex-direction: column!important;
  -webkit-flex-direction: column!important;
  -moz-flex-direction: column!important;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  text-align: center!important;
  color: #000000!important;
}
.react-confirm-alert-body, .react-confirm-alert-button-group > button {
  font-family: Open Sans, Arial, sans-serif!important;
  font-size: 1rem!important;
}
.react-confirm-alert-button-group > button {
  background-color: #000000!important;
  font-size: 14px!important;
}
.react-confirm-alert-overlay {
  background-color: rgba(246, 243, 235, 0.8)!important;
}
.bg_ar_down {
  background-image: url("./fonts/ar_down.svg");
}
.bg_ar_up {
  background-image: url("./fonts/ar_up.svg");
}
.react-datepicker-wrapper {
  width: 100%!important;
}
.fl_option_form .form-label {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  font-family: Monserrat Med, Arial, sans-serif;
  color: #1D1F1C;
}
.fl_option_form .sel-field {
  padding: 1.2rem!important;
  height: auto!important;
  font-family: Open Sans, Arial, sans-serif;
  color: #000000;
  background-color: #F3F0E7;
  border-radius: 0.75rem;
  border: none;
  background-image: url("./fonts/ar_down_bl.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  /* --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.fl_option_form .form-group, .total_sum_line {
  border-bottom: solid thin #D9D9D9;
}
.total_sum_line Button {
  background-color: #000000;
}
.pass-number {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
}
.pass-number .form-label {
  margin-bottom: 0!important;
  font-family: Open Sans, Arial, sans-serif;
  color: #1D1F1C;
}

.pass-number .kids::before {
  content: url('./fonts/kids.svg');
  height: 1.25rem;
  margin-right: 0.3rem;
}
.pass-number .adults::before {
  content: url('./fonts/adults.svg');
  height: 1.25rem;
  margin-right: 0.4rem;
}
.pass-number .hours::before {
  content: url('./fonts/clock.svg');
  height: 1.25rem;
  margin-right: 0.4rem;
}
.sbm-btn-disabled {
  background-color: transparent!important;
  color: #000000!important;
  text-decoration: underline;
  border:none!important;
  pointer-events: none;
}
.btn-disabled {
  opacity: 0.6;
  pointer-events: none;
}
.promos_block-main {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  flex-wrap: wrap;
}
.promos_block h4, .form-total-sum, .booking-block_cart .cart-total-sum, .monserrat-b {
  font-family: Monserrat Bold, Arial, sans-serif;
  margin-right: 1.5rem;
}
.promos_block h5, .promos_block h6, .promo_new-price, .monserrat-s {
  font-family: Monserrat, Arial, sans-serif;
}
.promos_block-main-block {
  width: 48%;
  margin: 1rem 0;
  background-color: #F6F3EB;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  border-radius: 40px;
  opacity: 1;
  animation: fade 1s linear;
  /* --smooth-corners: 7;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.promos_block-main-block .bg-img {
  border-radius: 40px 40px 0 0;
}
.promos_block-main-info {
  margin: 1.5rem 2rem 1rem;
}
.promos_block-main-block h5, .promos_block-main-descr, .promos_block-main-addit {
  margin-bottom: 1rem;
}
.promo_new-price {
  font-size: 1.25rem;
  font-family: Monserrat Bold, Arial, sans-serif;
}
.promo_old-price {
  color: #1D1F1C;
  font-weight: 600;
  opacity: 0.6;
  text-decoration: line-through;
}
.promos_block-main-descr {
  font-size: 0.875rem;
}
.modal-content {
  border: none;
  border-radius: 1.25rem;
  /* --smooth-corners: 8;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.modal-content .booking-block_cart {
  margin: 0!important;
}
.contacts-block Button {
  background-color: #000000;
}
.contacts-block_left, .contacts-block_right {
  width: 46%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
}
.clearfix:after {
  clear: both;
}
.contacts-block_subtitle {
  margin-bottom: 2rem;
  font-family: Monserrat Bold, Arial, sans-serif;
  font-size: 1.5rem;
  color: #1D1F1C;
}
.contacts-block_sm-icon {
  margin-right: 0.9rem;
}
.contacts-block_tel-icon {
  margin-right: 0.375rem;
}
.contacts-block_tel-icon ~ span {
  font-size: 0.875rem;
}
.contacts-form_input {
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #F1EDDF;
  border-radius: 12px;
  /* --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.contacts-form_input:active, .contacts-form_input:focus {
  outline: none !important;
  border: solid thin #ECE6D5;
  box-shadow: 0 0 10px #ECE6D5;
}
.contacts-form_input::placeholder {
  font-size: 0.875rem;
  color: #1D1F1C;
  opacity: 0.4;
}
#msg-sbm-btn {
  margin: 1.25rem 1rem 0 0!important;
}
.map-header {
  position: absolute;
  width: 20rem;
  max-width: 90%;
  height: 15rem;
  background-color: #fff;
  -webkit-box-shadow: 3px 0 13px rgba(211, 211, 211, 0.11);
  box-shadow: 3px 0 13px rgba(211, 211, 211, 0.11);
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 201;
}

.map-header:before {
  content: 'Загрузка карты...';
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 1.3vw;
  color: #ECE6D5;
  z-index: 201;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.map-header iframe {
  position: relative;
  border: solid thin #ECE6D5;
  box-shadow: 0 0 10px #ECE6D5;
  z-index: 201;
}

.map-header.active {
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 1;
  pointer-events: auto;
}
footer {
  background-color: #000000;
  color: #ffffff;
}
.footer-subtitle {
  font-size: 1.375rem;
  font-weight: 600;
  font-family: Monserrat, Arial, sans-serif;
}
footer .row {
  align-items: flex-start;
  -webkit-align-items: flex-start;
}
.footer-links li {
  padding: 0.5rem 0;
}
.copyrights {
  font-family: Gilroy, Arial, sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  opacity: 0.5;
}
.copyrights-mob {
  display: none;
}
a, a:hover {
  text-decoration: none;
  color: inherit;
}
ul:not(.privacy-policy ul) {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
button, input, select, textarea, #termsAgreement {
  padding: 1rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance:none;
  -moz-appearance: none;
  appearance:none;
  background-color: #f8f8f8;
  cursor: pointer;
  /* --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.f-button-pay {
  margin-right: 0!important;
}
#termsAgreement {
  width: 1rem!important;
  height: 1rem!important;
}
button, .btn {
  border-radius: 6px;
}
select::-ms-expand {
  display: none;
}
button {
  background-color: #f8f8f8;
  cursor: pointer;
}

.form {
  max-width: 60rem;
  margin: 0 auto;
}
.form > div {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  margin: 1rem;
}
.form label {
  margin: 1rem 0;
} */
/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 7rem;
  padding: 0.5rem;
  z-index: 1;
  background-color: #F7F4EC;
  text-align: right;
  margin: 0;
  border: solid thin grey;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}
/* Checkout Steps */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  -moz-flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #f08000;
  color: #f08000;
}
.back-home {
  text-decoration: underline;
}
/* .error-block-wrapper {
  min-height: 60vh!important;
  background-color: #000000;
}
.error-block-wrapper p, .error-block-wrapper h3 {
  color: white;
} */
.block-title-error p {
  margin-top: 60px!important;
}
.block-title-error .back-home {
  background-color: #000000;
  border-color: #ffffff;
  color: white;
  text-decoration: none;
  width: 350px;
  max-width: 100%;
  /* font-weight: 600; */
  margin-top: 2rem;
  cursor: pointer;
}
.block-title-error  {
  padding-top: 68px!important;
}
/* .error-downline {
  content: '';
  height: 100px;
  position: absolute;
  top: 100%;
  z-index: 10;
  background-color: #000000;
} */
/* Lounge page*/
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto auto;
  grid-gap: 50px;
  width: 100%!important;
}
.grid-container picture img {
  border-radius: 40px;
  object-fit: cover;
  width: 100%;
  max-height: 100%;
}
.lounge-gallery-1 {
  grid-area: 1 / 1 /span 2;
}
.lounge-gallery-2 {
  grid-area: 1 / 2 / span 1 / span 2;
}
.lounge-gallery-4 {
  grid-area: 2 / 3 / span 2 / span 1;
}
.lounge-gallery-5 {
  grid-area: 3 / 1 / span 2 / span 2;
}
.lounge-how-find {
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.lounge-how-find p {
  float: right;
  font-size: 28px;
  font-feature-settings: 'liga' off;
  color: #1D1F1C;
  font-family: Monserrat Med, Arial, sans-serif;
}
.lounge-is-block {
  margin-left: 50%;
  padding: 50px 0 100px 60px;
  width: 50%;
}
.lounge-is-figures, .lounge-advantages {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
}
.lounge-is-figure {
  width: 30%;
  margin-top: 40px;
}
.lounge-is-figure_name {
  font-size: 13px;
  color: #060604;
  opacity: 0.6;
  margin-bottom: 7px;
}
.lounge-is-figure_value {
  font-size: 22px;
  font-weight: 600;
  color: #1D1F1C;
}
.lounge-advantages {
  width: 100%;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
}
.fastline.lounge-advantages {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
}
.lounge-advantage {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 20px;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  width: 27%;
  padding: 15px 5px 10px;
  text-align: center;
  margin: 0 12px 24px;
}
.fastline .lounge-advantage {
  width: 22%;
  padding: 18px 20px 15px!important;
  margin: 0 0 40px!important;
}
.lounge-advantage > * {
  color: #212529;
  margin-top: 5px;
  margin-bottom: 5px;
}
/* covid page */
.covid-find-lab {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
}
.covid-find-lab img {
  width: auto!important;
  max-width: 100%!important;
  margin: 1rem auto;
}
.covid-find-lab_logo {
  width: 220px!important;
  max-width: 100%important;
}
.covid-block-wrap {
  padding: 50px 80px;
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: #FCFCFB;
  box-shadow: 0px -25px 60px -28px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
}
.covid-info-ukraine {
  background-image: url("./fonts/covid-info-ukraine.png");
  background-repeat: no-repeat;
  background-position: left bottom;
}
.covid-info-ukraine .block_title {
  margin-bottom: 210px!important;
}
.covid-info-ukraine .text_block {
  width: 50%;
  margin-left: 50%;
  bottom: 220px;
  position: relative;
}
.covid-advs {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
}
.covid-adv {
  display: flex;
  display: -webkit-flex;
  width: 46%;
  padding: 1rem;
  margin-bottom: 40px;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  background-color: #FFFFFF;
  border-radius: 20px;
}
.covid-adv p {
  margin: 0 0 0 1rem;
  font-weight: 300;
font-size: 14px;
color: #1D1F1C;
}
.covid-faq-item {
  padding: 20px 40px;
  background: #FFFFFF;
  border-radius: 30px;
  margin-bottom: 40px;
}
.covid-faq-item-short {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
}
.covid-faq-item-toggle {
  width: 60px;
  height: 60px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}
.covid-faq-item-toggle img {
  width: 60px;
  height: 60px;
}
.covid-faq-item-q {
  font-family: Monserrat, Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #343434;
  margin: 0 20px 0 0;
}
.covid-faq-item-a {
  font-size: 16px;
  color: #343434;
}
.covid-faq-item-a p {
  margin: 20px 0 0;
}
.double-banner-ads {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.double-banner-ads > a {
  width: 48%;
}
.double-banner-ads > a > img {
  width: 100%;
}
.single-banner-ad {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.single-banner-ad > a > img {
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .fl-services-block .inner-wr {
    min-height: 260px!important;
  }
  .fl-services-block .fl-services-block_category-name {
    min-height: 50px!important;
  }
  .navbar-nav > *, .navbar-nav > * > * {
    font-size: 0.9rem!important;
  }
  .main-banner_left h1 {
    font-size: 2.5rem;
  }
  .fl-services-block_category:nth-child(4) .fl-services-block_category-descr {
    margin-bottom: 3rem!important;
  }
  .about.main-banner {
    background-size: 50%;
    background-position-y: 70px;
  }
  .fastline.main-banner {
    background-position-y: center!important;
  }
  .cart Button {
    padding-right: 0.1rem;
    padding-left: 0.35rem;
  }
  .stats-block {
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    padding: 1.5rem 0;
  }
  .stats-airports {
    margin-bottom: 3rem;
  }
  .about .stats-airports {
    margin-bottom: 0;
  }
  .stats-airports img {
    margin-left: 1rem;
  }
  /* .about.main-banner .stats-airports {
    margin-bottom: 2rem!important;
  } */
  .stats-numbers div:last-child {
    flex-basis: 30%;
    -webkit-flex-basis: 30%;
    -moz-flex-basis: 30%;
  }
  .stats-number {
    text-align: center;
  }
  .about .stats-number {
    text-align: left;
  }
  .services-block_category {
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
  }
  .services-block_category-descr {
    height: 33.5%;
  }
  .services-block_category-book {
    padding: 0.25rem 0.625rem;
  }
  .services-block_category-more {
    margin-top: 0.75rem;
  }
}

@media screen and (max-width: 1024px) {
  .lounge-wrapper {
    background-size: auto 100%!important;
  }
  .lounge-is-wrapper {
    background-size: auto 100%!important;
  }
}
@media screen and (max-width: 991px) {
  .breadcrumb {
    padding: 0 1rem!important;
  }
  .fl-services-block .inner-wr {
    min-height: 180px!important;
  }
  .fl-services-block .fl-services-block_category-name {
    min-height: unset!important;
  }
  .block_title-name {
    font-size: 1.5rem!important;
  }
  .about.main-banner {
    padding-bottom: 30px!important;
  }
  .about.main-banner .main-banner_left {
    text-align: center;
  }
  .fastline.main-banner .main-banner_left {
    align-items: center;
  }
  .fastline.main-banner {
    background-position-y: 2rem!important;
  }
  .about.main-banner h1 {
    width: 100%;
    margin-bottom: 0!important;
  }
  .about.main-banner .stats-airports {
    align-self: center;
  }
  .about .stats-number {
    text-align: center;
  }
  .about-services .services-block_category {
    width: 45%!important;
  }
  .partners.services-block_category.commission {
    width: 100%!important;
  }
  .fl-services-block_categories {
    justify-content: center!important;
  }
  .fl-services-block_category {
    width: calc(48% - 1.4rem)!important;
    margin-left: 0.7rem!important;
    margin-right: 0.7rem!important;
  }
  .fl_option_order-btn {
    margin-right: 0!important;
  }
  .about-company img {
    max-width: 60%;
  }
  .f-logo {
    margin: 0 auto;
  }
  #f .f-btn-block {
    width: 550px!important; 
    left: 0!important;
  }
  .lg_screen {
    display: none;
  }
  .sm_screen{
    display: inline;
  }
  .form_inline_block {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
  }
  .form_inline_block_inner {
    width: 90%;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
  }
  .form_inline_block_inner > * {
    width: 50%;
  }
  .form_flex_basis {
    width: 100%!important;
  }
  .navbar-brand {
    margin-right: auto;
    order: 1!important;
    -webkit-order: 1!important;
    -moz-order: 1!important;
    padding: 1rem 0 0.5rem!important; 
  }
  .cart {
    order: 2!important;
    -webkit-order: 2!important;
    -moz-order: 2!important;
    padding: 1rem 0 0!important; 
    transition: display 2s linear;
  }
  #basic-navbar-nav {
    justify-content: flex-end!important;
    -webkit-justify-content: flex-end!important;
    -moz-justify-content: flex-end!important;
    background-color: #F6F3EB;
  }
  .navbar {
    justify-content: flex-end;
    padding: 1.5rem 1rem 2rem!important;
  }
  .navbar-toggler {
    order: 3!important;
    -webkit-order: 3!important;
    -moz-order: 3!important;
    padding-bottom: 0!important;
    margin-right: 0;
    border: none!important;
  }
  .navbar-collapse {
    order: 4!important;
    -webkit-order: 4!important;
    -moz-order: 4!important;
  }
  .navbar-light .navbar-toggler-icon {
    padding-bottom: 0!important;
    margin-top: 1rem!important;
    background-image: url('./fonts/burger.svg');
    background-size: 1.4rem 1rem;
  }
  .cart Button, .cart Button:hover, .cart Button:focus, .cart Button:active {
    background-color: transparent!important;
    color: #000000!important;
    border: solid thin #757575!important;
    margin-right: 0;
  }
  .cart_close_btn {
    display: inline;
    margin: 0;
    padding: 0;
    float: right;
    font-weight: bold;
    cursor: pointer;
  }
  .main-banner {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .partners.main-banner {
    padding-top: 370px;
    background-position: center 2rem;
    background-size: 300px;
  }
  .fastline.main-banner, .about.main-banner {
    padding-top: 350px!important;
  }
  .partners.main-banner p, .lounge.main-banner p {
    margin: 0.5rem 0 1.25rem;
  }
  .main-banner_left, .about .main-banner_left {
    width: 100%!important;
  }
  .main-banner_left h1  {
    width: 85%;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .main-banner_left-icons {
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    margin: -1rem 0 1rem;
  }
  figure {
    margin: 1rem 0!important;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
  }
  .services-block {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
  .services-block_categories {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    width: 100%;
  }
  .services-block_category {
    width: 45%;
    margin-bottom: 2rem;
  }
  .partners.services-block_category {
    padding: 2.5rem;
  }
  .services-block_category-descr {
    height: 31%;
  }
  .about-services .block_title {
    padding: 2rem 3rem 1.5rem!important;
  }
  .partners-instr-block {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
  }
  .partners-instr-block .services-block_category {
    width: 100%;
  }
  .partners-instr-block img {
    width: 70%;
    margin-bottom: 2.5rem;
  }
  .fl_option_short {
    flex-direction: column;
    padding-bottom: 1rem;
  }
  .fl_option_collapse-btn:not(.copy-btn) {
    left: 50%;
    transform: translateX(-50%);
  }
  .booking-block .form-row {
    width: 100%;
  }
  .promos_block-main-block {
    width: 100%!important;
  }
  .contacts-block_main {
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
  }
  .contacts-block_left, .contacts-block_right {
    width: 85%;
  }
  .contacts-block_right {
    margin-top: 3rem;
  }
  .checkout_block_cart, .checkout_block_personal {
    width: 100%!important;
  }
  .checkout_block_cart {
    margin-bottom: 2rem;
  }
  .grid-container {
    grid-gap: 30px!important;
  }
  .lounge-is-figure {
    width: 45%!important;
  }
  .lounge-advantage {
    width: 41%!important;
    padding-top: 12px!important;
    padding-bottom: 0!important;
  }
  .fastline.lounge-advantages {
    justify-content: space-around!important;
  }
  .fastline .lounge-advantage {
    width: 30%!important;
  }
  .covid-wrapper {
    background-position-x: 13%!important;
  }
  .covid-wrapper .main-banner_left {
    width: 60%!important;
    padding-right: 0!important;
  }
  .covid-info-ukraine .block_title {
    margin-bottom: initial!important;
  }
  .covid-info-ukraine .text_block {
    width: 100%;
    margin-left: 0;
    bottom: 0;
    margin-bottom: 520px!important;
  }
  .covid-info-ukraine {
    background-position: 25% bottom;
  }
  .covid-adv {
    width: 48%;
    margin-bottom: 20px!important;
  }
}
@media screen and (max-width: 767px) {
  .double-banner-ads {
    flex-wrap: wrap;
    flex-wrap: -webkit-wrap;
  }
  .double-banner-ads > a {
    width: 100%;
  }
  .double-banner-ads > a:first-child {
    margin-bottom: 3rem;
  }
  .breadcrumbs_bg {
    background-color: #F6F3EB!important;
  }
  .main-banner {
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
    background-position: calc(100% + 3rem) 8rem;
  }
  .main-banner_left h1, .lounge .main-banner_left h1  {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .main-banner_left h1:not(.lounge .main-banner_left h1, .about .main-banner_left h1) {
    margin-top: -1.5rem;
  }
  .main-banner_left-icons {
    margin: 0 0 2rem!important;
  }
  .covid.main-banner {
    padding: 2.5rem 1rem!important;
  }
  .stats-airports {
    margin-bottom: 2rem;
  }
  .aiports-imgs {
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    margin-top: 0.75rem;
  }
  .stats-airports img {
    margin: 0;
  }
  .stats-numbers div, .stats-numbers div:last-child {
    flex-basis: 32%;
    -webkit-flex-basis: 32%;
    -moz-flex-basis: 32%;
    text-align: center;
  }
  .about .stats-numbers div {
    margin: 0;
  }
  .stats-number {
    text-align: center;
  }
  .about-company {
    line-height: 24px;
  }
  .about-company img {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
  .booking-block {
    position: relative;
  }
  .services-block_category {
    width: 98%;
    height: 33rem;
    padding-bottom: 1.25rem;
  }
  .fl-services-block_category {
    width: 98%!important;
  }
  .fastline.lounge-advantages {
    justify-content: space-between!important;
    -webkit-justify-content: space-between!important;
    -moz-justify-content: space-between!important;
  }
  .fastline .lounge-advantage {
    width: 46%!important;
  }
  .fl-services-block .inner-wr {
    min-height: unset!important;
  }
  .fl-services-block_category:nth-child(4) .fl-services-block_category-descr {
    margin-bottom: 1rem!important;
  }
  .services-block_category-name:not(.partners .services-block_category-name) {
    height: 70%!important;
    margin-bottom: 0!important;
  }
  .services-block_category-descr {
    height: 33%;
  }
  .partners.services-block_category.commission.partners-category {
    width: 100%;
  }
  .partners.services-block_category.commission.partners-category:last-child {
    margin-top: 5.5rem!important;
  }
  .partners-instr-block img {
    width: 100%;
  }
  .about-services .services-block_category {
    width: 98%!important;
  }
  .about-services .block_title {
    padding: 2rem 1rem 1.5rem!important;
  }
  .booking-block_aside {
    width: 100%;
  }
  .booking-block_menu ul {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
  }
  .booking-block_menu li {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    width: 23%;
    padding: 2rem 1rem 0.5rem;
    text-align: center;
    border: solid thin #ffffff;
    border-radius: 8px;
    /* --smooth-corners: 20;
    mask-image: paint(smooth-corners);
    -webkit-mask-image: paint(smooth-corners); */
  }
  .booking-block_menu li.active {
    background-color: #ffffff;
    color: #000000;
  }
  .booking-block_menu li::before {
    top: 0.6rem;
    left: 50%;
    transform: unset;
    transform: translateX(-50%);
  }
  .booking-block_menu li:first-child.active::before {
    content: url('./fonts/luggage_black.svg');
  }
  .booking-block_menu li:nth-child(2).active::before {
    content: url('./fonts/covid_black.svg');
  }
  .booking-block_menu li:nth-child(3).active::before {
    content: url('./fonts/bl_black.svg');
  }
  .booking-block_menu li:nth-child(4).active::before {
    content: url('./fonts/so_icon_black.svg');
  }
  .booking-block_cart ul li {
    width: 100%;
  }
  .booking-block_cart ul li .trash_icon {
    margin-left: 0.5rem;
    visibility: visible;
  }
  .booking-block_main {
    flex-wrap: wrap;
  }
  .booking-block_content {
    padding-left: 0;
  }
  .booking-block_content h5:not(.promos_block-main-block h5) {
    margin-top: 2rem;
  }
  .booking-block_menu, .booking-block_cart {
    max-width: unset;
  }
  .sdf_link_lg_screen {
    display: none;
  }
  .sdf_link_sm_screen {
    display: block!important;
    width: 100%;
    max-width: none;
  }
  .cart_btns-content {
    display: inline;
  }
  .fl_option_form {
    padding-top: 2rem;
    position: relative;
  }
  .fl_option_form form {
    margin-top: 1rem;
  }
  #f .f-header {
    padding-left: 1.5rem!important;
    padding-bottom: 0.5rem!important;
  }
  #f .f-block-title {
    margin-bottom: 0.5rem;
  }
  #f .f-wrapper {
    flex-direction: column!important;
    -webkit-flex-direction: column!important;
    -moz-flex-direction: column!important;
    align-items: center;
  }
  #f .f-btn-block, #f .f-center, #f .f-info, #f .f-wrapper {
    width: 320px!important;
    max-width: 100%!important;
  }
  #f .f-info {
    text-align: center;
  }
  .contacts-block_left, .contacts-block_right {
    width: 98%;
  }
  .total_sum_line .cart_btns {
    position: fixed;
    width: fit-content!important;
    max-width: calc(100% - 2rem);
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: flex;
    display: -webkit-flex;
  }
  .total_sum_line .cart_btns Button {
    margin: 0 0.25rem;
    padding: 0.5rem 0.8rem;
    background-color: #000000!important;
    color: #ffffff!important;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.5);
  }
  footer .row {
    align-items: center;
  }
  footer .row > * {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .copyrights-desk {
    display: none;
  }
  .copyrights-mob {
    display: block;
    margin-top: 50px;
  }
  .grid-container {
    grid-gap: 1rem!important;
  }
  .lounge-how-find {
    height: 350px!important;
  }
  .lounge-how-find p {
    font-size: 20px!important;
  }
  .lounge-wrapper {
    background-size: 100% auto!important;
    background-position: left bottom!important;
  }
  .lounge .main-banner_left {
    align-items: center!important;
    -webkit-align-items: center!important;
    -moz-align-items: center!important;
    text-align: center;
    width: 100%!important;
    margin-bottom: 750px!important;
    padding-right: 0!important;
  }
  .lounge-is-block {
    width: 100%!important;
    margin-left: auto!important;
    padding-left: 0!important;
    padding-bottom: 800px!important;
  }
  .lounge-is-wrapper {
    background-position: left bottom!important;
    background-size: 100% auto!important;
  }
  .lounge-advantage {
    width: 40%!important;
    padding-top: 7%!important;
    padding-bottom: 3%!important;
  }
  .covid-wrapper .main-banner_left {
    margin-bottom: 340px!important;
  }
  .covid-block-wrap {
    padding: 20px 30px 40px;
  }
  .covid-adv {
    width: 100%!important;
  }

  .covid-faq-item {
    padding: 20px 30px;
    margin-bottom: 20px;
  }
  .covid-faq-item-toggle, .covid-faq-item-toggle img {
    width: 60px;
    height: 60px;
  }
  .covid-faq-item-q {
    font-size: 16px;
  }
  .covid-faq-item-a {
    font-size: 16px;
    color: #343434;
  }
  .covid-faq-item-a p {
    margin: 15px 0 0;
  }
  .covid-block-wrap {
    border-radius: 30px!important;
  }
  .block_title {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
  }
}
@media screen and (max-width: 690px) {
  .lounge .main-banner_left {
    margin-bottom: 680px!important;
  }
  .covid-wrapper .main-banner_left {
    margin-bottom: 290px!important;
  }
  .lounge-is-block {
    padding-bottom: 730px!important;
    margin-left: 0!important;
    margin-right: 0!important;
  }
  .covid-info-ukraine {
    background-position: left bottom;
  }
}
@media screen and (max-width: 600px) {
  .main-banner_left h1 {
    width: 100%;
  }
  /* .main-banner { */
    /* background-position: calc(100% + 5rem) 8rem; */
  /* } */
  .about .main-banner_left h1 {
    font-size: 1.3rem;
  }
  .cart {
    min-width: unset!important;
  }
  .cart Button {
    min-width: unset!important;
    padding: 0.2rem;
  }
  .cart-text {
    display: none;
  }
  .stats-numbers {
    flex-direction: column;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    align-items: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    flex-basis: 100%;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
  }
  .stats-numbers div, .stats-numbers div:last-child {
    flex-basis: 90%;
    -webkit-flex-basis: 90%;
    -moz-flex-basis: 90%;
    margin: 0.75rem 0;
  }
  .stats-number, .stats-descr {
    width: 100%;
  }
  .services-block_category-name:not(.partners .services-block_category-name) {
    height: 65%!important;
  }
  .contacts-block_sm-icon {
    margin-right: 0.4rem;
  }
  .contacts-block_tel-icon {
    margin-right: 0.2rem;
  }
  .grid-container {
    grid-template-columns: 100%!important;
    grid-template-rows: auto!important;
    grid-gap: 1rem 0!important;
  }
  .grid-container picture img {
    border-radius: 0!important;
  }
  .lounge-gallery-2 {
    grid-area: 3 / 1 !important;
  }
  .lounge-gallery-4 {
    grid-area: 4 / 1 !important;
  }
  .lounge-gallery-5 {
    grid-area: 6 / 1 !important;
  }
  .lounge-how-find {
    height: 250px!important;
  }
  .lounge-how-find p {
    font-size: 16px!important;
  }
  .lounge .main-banner_left {
    margin-bottom: 590px!important;
  }
  .lounge-is-block {
    padding-bottom: 650px!important;
    padding-left: 0!important;
  }
  .covid-wrapper .main-banner_left {
    margin-bottom: 260px!important;
  }
  .covid-block-wrap {
    margin-left: 1rem;
    margin-right: 1rem;
    width: calc(100% - 2rem)!important;
  }
}
@media screen and (max-width: 575px) {
  .main-banner {
    background-image: url("./fonts/airstairs-sm.webp");
    background-image: image-set(
      url("./fonts/airstairs-sm.jpg") type("image/jpeg"),
      url("./fonts/airstairs-sm.webp") type("image/webp"));
    background-image: webkit-image-set(
      url("./fonts/airstairs-sm.jpg") type("image/jpeg"),
      url("./fonts/airstairs-sm.webp") type("image/webp"));
  }
  .booking-block-wrap, .stats-block-wrap {
    padding: 0 1rem;
  }
  .about .stats-block-wrap {
    padding: 1.5rem 1rem 0;
  }
  .stats-airports {
    font-size: 1rem;
  }
  .lounge .main-banner_left {
    margin-bottom: 570px!important;
  }
  .covid-wrapper .main-banner_left {
    margin-bottom: 240px!important;
  }
  .lounge-is-block {
    padding-bottom: 580px!important;
  }
  .covid-info-ukraine {
    background-position: left -15% bottom;
  }
  .block-title-error {
    margin-left: 1rem!important; 
    margin-right: 1rem!important;
    width: calc(100% - 2rem);
  }
  .booking-block_menu ul {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
  }
  .booking-block_menu li {
   width: 30%!important;
  }
  .booking-block_menu li:nth-child(4) {
    width: 40%!important;
    margin: 1rem auto 0;
   }
}
@media screen and (max-width: 480px) {
  .form_inline_block_inner {
    width: 98%!important;
    justify-content: space-between!important;
    -webkit-justify-content: space-between!important;
    -moz-justify-content: space-between!important;
  }
  .form_inline_block_inner > * {
    width: 30%;
  }
  .main-banner {
    background-position: calc(100% + 5rem) center;
  }
  .services-block_category-descr {
    height: 33%;
  }
  .total_sum_line > p {
    font-size: 1rem;
  }
  .services-block_category-name:not(.partners .services-block_category-name) {
    height: 35%!important;
  }
  .contacts-block_tel {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
  }
  .contacts-block_tel > * {
    width: 48%;
  }
  .contacts-block_tel > *:last-child {
    margin-top: 1rem;
    width: 95%;
  }
  .contacts-block_tel-icon {
    margin-right: 0.4rem;
  }
  .lounge-how-find {
    height: 200px!important;
  }
  .lounge-how-find p {
    font-size: 12px!important;
  }
  .lounge .main-banner_left {
    margin-bottom: 480px!important;
  }
  .covid-wrapper .main-banner_left {
    margin-bottom: 200px!important;
  }
  .lounge-is-block {
    padding-bottom: 450px!important;
  }
  .covid-info-ukraine {
    background-position: right 10vw bottom;
  }
}
@media screen and (max-width: 450px) {
  .services-block_category {
    width: 98%;
    height: 30rem;
    margin-bottom: 1.5rem;
  }
  .services-block_category:last-child {
    margin-bottom: 0;
  }
  .lounge .main-banner_left {
    margin-bottom: 450px!important;
  }
  .covid-wrapper .main-banner_left {
    margin-bottom: 180px!important;
  }
}
@media screen and (max-width: 400px) {
  .services-block_category {
    width: 98%;
    height: 30rem;
    margin-bottom: 1.5rem;
  }
  .booking-block_content {
    width: calc(100% - 2rem);
  }
  .contacts-block_sm {
    justify-content: space-evenly;
    -webkit-justify-content: space-evenly;
    -moz-justify-content: space-evenly;
  }
  /* .contacts-block_sm-icon { */
    /* margin-right: 1.5rem; */
  /* } */
  /* .contacts-block_sm-icon ~ img {
    display: none;
  } */
  .lounge .main-banner_left {
    margin-bottom: 390px!important;
  }
  .covid-wrapper .main-banner_left {
    margin-bottom: 160px!important;
  }
  .about .stats-airports img {
    margin: 0 0.5rem 0 0!important;
  }
  .booking-block_menu li {
    width: 33%!important;
   }
   .booking-block_menu li:nth-child(4) {
     width: 55%!important;
    }
}
@media screen and (max-width: 365px) {
  #f .f-wrapper, #f .f-btn-block {
    margin: 0 1rem!important;
  }
  #f .f-btn-block, #f .f-center, #f .f-info, #f .f-wrapper {
    width: 280px!important;
  }
  .services-block_category {
    height: 30rem;
  }
  .services-block_category-descr:not(.about-services .services-block_category-descr) {
    width: 90%;
  }
  .about-services .services-block_category-descr {
    height: 40%!important;
  }
  .lounge .main-banner_left {
    margin-bottom: 340px!important;
  }
  .covid-wrapper .main-banner_left {
    margin-bottom: 150px!important;
  }
  .lounge-is-block {
    padding-bottom: 400px!important;
  }
}
@media screen and (max-width: 330px) {
  .services-block_category {
    height: 29rem;
  }
  .cart_icon-index {
    top: 1.1rem;
  }
  .services-block_category-descr:not(.about-services .services-block_category-descr) {
    height: 35%;
  }
}
@media screen and (min-width: 1600px) {
  .lounge-wrapper {
    /* background-size: 40% 100%!important; */
    /* max-width: 1440px!important; */
  }
  .lounge-is-wrapper {
    /* background-size: 39% 100%!important; */
    /* max-width: 1440px!important; */
  }
}
@media screen and (min-width: 2000px) {
  .lounge-wrapper {
    /* background-size: 32% 100%!important; */
  }
  .lounge-is-wrapper {
    /* background-size: 32% 100%!important; */
  }
}
@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}
 .quantity {
  position: relative;
  padding: 0 2rem;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}
input[type=number]
{
  -moz-appearance: textfield;
}
.quantity input {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
}
.quantity input:focus {
  outline: 0;
}
.quantity-nav {
  float: left;
  position: relative;
}
.quantity-button {
  position: relative;
  width: 1.5rem;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  background-color: #000000;
  border-radius: 6px;
  /* --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners); */
}
.quantity-button.quantity-up {
  position: absolute;
  left: 2.7rem;
  top: -2rem;
}
.quantity-button.quantity-down {
  position: absolute;
  left: -0.5rem;
  top: -2rem;
} 
/* checkout */
.fl_option_details-inner {
  width: 100%;
}
.cart_list p {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  font-size: 13px;
}

.cart_list p span:first-child {
  margin-right: 1rem;
}
.cart_list p span:nth-child(2) {
  text-align: right;
}